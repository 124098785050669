<template>
    <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
      <h1>Twilio Call Inbox</h1>
      <form @submit.prevent="createChannel" class="mx-0 flex flex-wrap">
        <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
          <label :class="{ error: errors.channelName }">
            Inbox Name:
            <input
              v-model.trim="channelName"
              type="text"
              :placeholder="$t('INBOX_MGMT.ADD.TWILIO.CHANNEL_NAME.PLACEHOLDER')"
            />
            <span v-if="errors.channelName" class="message">
              {{ $t('INBOX_MGMT.ADD.TWILIO.CHANNEL_NAME.ERROR') }}
            </span>
          </label>
        </div>
        <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
          <label :class="{ error: errors.phoneNumber }">
            Phone Number:
            <input
              v-model.trim="phoneNumber"
              type="text"
              :placeholder="$t('INBOX_MGMT.ADD.TWILIO.PHONE_NUMBER.PLACEHOLDER')"
            />
            <span v-if="errors.phoneNumber" class="message">
              {{ $t('INBOX_MGMT.ADD.TWILIO.PHONE_NUMBER.ERROR') }}
            </span>
          </label>
        </div>
        <div class="w-full">
          <woot-submit-button
            :loading="uiFlags.isCreating"
            :button-text="$t('INBOX_MGMT.ADD.TWILIO.SUBMIT_BUTTON')"
          />
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  import alertMixin from 'shared/mixins/alertMixin';
  import router from '../../../../index';
  import { isPhoneE164OrEmpty } from 'shared/helpers/Validators';
  
  export default {
    mixins: [alertMixin],
    data() {
      return {
        channelName: '',
        phoneNumber: '',
        errors: {
          channelName: false,
          phoneNumber: false,
        },
      };
    },
    computed: {
      ...mapGetters({
        uiFlags: 'inboxes/getUIFlags',
        accountId: 'getCurrentAccountId',
      }),
    },
    methods: {
      validateFields() {
        if (!this.channelName) {
          this.errors.channelName = true;
        } else {
          this.errors.channelName = false;
        }
          if (!this.phoneNumber || !isPhoneE164OrEmpty(this.phoneNumber)) {
          this.errors.phoneNumber = true;
        } else {
          this.errors.phoneNumber = false;
        }
  
        return !this.errors.channelName && !this.errors.phoneNumber;
      },
      async createChannel() {
        // if (!this.validateFields()) {
        //   return;
        // }
        try {
          const twilioChannel = await this.$store.dispatch(
            'inboxes/createTwilioCallChannel',
            {
              twilio_channel: {
                name: this.channelName,
                type: 'call',
                phone_number: `+${this.phoneNumber.replace(/\D/g, '')}`,
                accountId: this.accountId,
              },
            }
          );
          router.replace({
            name: 'settings_inboxes_add_agents',
            params: {
              page: 'new',
              inbox_id: twilioChannel.id,
            },
          });
        } catch (error) {
          this.showAlert(this.$t('INBOX_MGMT.ADD.TWILIO.API.ERROR_MESSAGE'));
        }
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .messagingServiceHelptext {
    margin-top: -10px;
    margin-bottom: 15px;
  
    .checkbox {
      margin: 0px 4px;
    }
  }
  </style>  